import React from 'react';
import Button from '@components/atoms/Button';
import Container from '@components/atoms/Container';
import MarketoForm from '@components/atoms/MarketoForm';
import PropTypes from 'prop-types';

import './style.form-image-block.scss';

const FormImageBlock = ({
    form,
    image,
    submission,
    spacing,
    formSubmit,
    onFormSubmit
}) => {

    const marginTopClass = spacing && spacing.marginTop ? 'hudl-block__margin-top' : '';
    const marginBottomClass = spacing && spacing.marginBottom ? 'hudl-block__margin-bottom' : '';
    const classes = [ 'hudl-form-image-block', marginTopClass, marginBottomClass ];

    let showPageContent = null;

    if (typeof submission !== 'undefined' && formSubmit === true) {
        showPageContent = (
            <div className='hudl-one-column-text hudl-padding-y--lg hudl-text-align--center'>
                <h2 className="hudl-beta-type">{submission.submissionTitle}</h2>
                <p className="hudl-margin-bottom--sm">{submission.submissionDescription}</p>
                {submission.submissionButtonBlockOptional && (
                    <Button
                        variant={submission.submissionButtonBlockOptional.buttonStyle}
                        to={submission.submissionButtonBlockOptional.buttonUrl}
                        className="hudl-button hudl-margin-bottom--sm"
                        newPage={submission.submissionButtonBlockOptional.newPage}
                    >
                        {submission.submissionButtonBlockOptional.buttonContent}
                    </Button>
                )}
                <br />
                {submission.buttonBlockOptional && (
                    <Button
                        variant={submission.buttonBlockOptional.buttonStyle}
                        to={submission.buttonBlockOptional.buttonUrl}
                        className="hudl-button"
                        newPage={submission.buttonBlockOptional.newPage}
                    >
                        {submission.buttonBlockOptional.buttonContent}
                    </Button>
                )}
            </div>
        );
    } else {
        showPageContent = (
            <div className={classes.join(' ')} style={{backgroundImage: `url(${image.image})`}}>
                <div className="hudl-form-image-block__inner">
                    <Container size="medium">
                        <div className="hudl-form-image-block__title hudl-form-image-block__content">
                            <h2 className="hudl-form-image-block__title hudl-beta-type">{form.formTitle}</h2>
                            <p className="hudl-form-image-block__intro hudl-margin-top--md hudl-margin-bottom--xl">{form.formDescription}</p>
                        </div>
                        <div className="hudl-form-image-block__form">
                            {submission ? (
                                <MarketoForm form={form} formSubmit={formSubmit} onFormSubmit={onFormSubmit} showThanksMessage={submission.submissionUseThanks} />
                            ) : (
                                <MarketoForm form={form} formSubmit={formSubmit} onFormSubmit={onFormSubmit} showThanksMessage={false} />
                            )}
                        </div>
                    </Container>
                </div>
            </div>
        );
    }

    return (
        <>
            {showPageContent}
        </>
    );
};

export default FormImageBlock;

FormImageBlock.propTypes = {
    form: PropTypes.shape({
        formTitle: PropTypes.string,
        formDescription: PropTypes.string,
        formId: PropTypes.number.isRequired
    }),
    image: PropTypes.shape({
        image: PropTypes.string.isRequired,
        imageAlt: PropTypes.string,
        imageTitle: PropTypes.string
    }),
    submission: PropTypes.shape({
        submissionUseThanks: PropTypes.bool,
        submissionTitle: PropTypes.string,
        submissionDescription: PropTypes.string,
        buttonBlockOptional: PropTypes.shape({
            buttonContent: PropTypes.string.isRequired,
            buttonUrl: PropTypes.string.isRequired,
            newPage: PropTypes.bool
        }),
        submissionButtonBlockOptional: PropTypes.shape({
            buttonContent: PropTypes.string.isRequired,
            buttonUrl: PropTypes.string.isRequired,
            newPage: PropTypes.bool
        })
    })
};

FormImageBlock.defaultProps = {
    form: {},
    image: {},
    submission: []
};
