/**
 * @param {Object} dataLayerData The dataLayerData code is passed to the function from the page context.
 * @returns {string} Returns the url of the previous_page from the dataLayer.
 * @description The dataLayer is reversed and the function finds the first previous page value.
 */
const getPrevPage = (dataLayerData) => {

    // Reverse the array in the datalayer.
    const newDataLayerData = dataLayerData.slice().reverse();

    // Find the first page_load event.
    const firstPageLoadObject = newDataLayerData.find( ({event}) => event === 'page_load');

    // Get the previous_page value from the object.
    const previousPage = firstPageLoadObject.previous_page ? firstPageLoadObject.previous_page : '';

    return previousPage;
};

export default getPrevPage;
