import React, {useEffect} from 'react';
import Container from '@components/atoms/Container';

import './style.one-trust-cookie-block.scss';

const OneTrustCookieBlock = ({
    title,
    spacing
}) => {

    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (typeof window.OneTrust !== 'undefined') {
                // Forces the initializeCookiePolicyHtml function to run and the html
                // to be pushed into the OT cookie list div #ot-sdk-cookie-policy.
                window.OneTrust.initializeCookiePolicyHtml();
            }
        }
    });

    const marginTopClass = spacing && spacing.marginTop ? 'hudl-block__margin-top' : '';
    const marginBottomClass = spacing && spacing.marginBottom ? 'hudl-block__margin-bottom' : '';
    const classes = [ 'hudl-one-trust-cookie-button', 'hudl-padding-y--md', marginTopClass, marginBottomClass ];

    const openCookieSettings = (e) => {
        window.Optanon.ToggleInfoDisplay();
    };

    return (
        <div className={classes.join(' ')}>
            <Container size="small">
                <button type="button" className="hudl-button hudl-button--primary-button-dark one-trust hudl-margin-bottom--sm" onClick={(e) => openCookieSettings(e)}>{title}</button>
                <div id="ot-sdk-cookie-policy" />
            </Container>
        </div>
    );
};

export default OneTrustCookieBlock;
