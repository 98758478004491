import React from 'react';
import Button from '@components/atoms/Button';
import MarkdownField from '@components/atoms/MarkdownField';
import {useLanguageContext} from '@helpers/LanguageContext';
import notchHelper from '@helpers/notches';
import PropTypes from 'prop-types';

import './style.stats-block.scss';
import '../../../sass/core/_notches.scss';

const StatsBlock = ({
    title,
    content,
    buttonBlockOptional: buttonBlock,
    statList,
    spacing,
    notches
}) => {
    const {pageLanguageCode} = useLanguageContext();

    const marginTopClass = spacing && spacing.marginTop ? 'hudl-block__margin-top' : '';
    const marginBottomClass = spacing && spacing.marginBottom ? 'hudl-block__margin-bottom' : '';
    const classes = [ 'hudl-stats-block', 'hudl-padding-y--xxl', marginTopClass, marginBottomClass ];

    const hasButton = buttonBlock && buttonBlock.buttonUrl && buttonBlock.buttonContent;

    // Create list of stat items.
    const stats = statList.map((stat, index) => {
        return (
            <li key={index.toString()} className="hudl-stats-block__stat" lang={pageLanguageCode}>
                <span className="hudl-stats-block__stat-title hudl-gamma-type">{stat.statNumber}</span>
                <span className="hudl-stats-block__stat-subtitle hudl-delta-type">{stat.statLabel}</span>
            </li>
        );
    });

    const {NotchLayout} = notchHelper({notches, classArray: classes});

    return (
        <div className={classes.join(' ')}>
            { notches && (
                <NotchLayout notchesOptions={notches} />
            ) }
            <div className="hudl-stats-block__gradient hudl-stats-block__gradient--top" />
            <div className="hudl-container hudl-container--small hudl-padding-x--md">
                <h2 className="hudl-stats-block__title hudl-gamma-type">{title}</h2>
                <MarkdownField content={content} />
            </div>
            <div className="hudl-container hudl-container--medium hudl-padding-x--md">
                { hasButton && <Button component="stats_block" variant={buttonBlock.buttonStyle} to={buttonBlock.buttonUrl} className="hudl-button" newPage={buttonBlock.newPage}>{buttonBlock.buttonContent}</Button> }
                <ul className="hudl-stats-block__stats-list">
                    {stats}
                </ul>
            </div>
            <div className="hudl-stats-block__gradient hudl-stats-block__gradient--bottom" />
        </div>
    );
};

export default StatsBlock;

StatsBlock.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired, // Markdown.
    buttonBlock: PropTypes.shape({
        buttonContent: PropTypes.string,
        buttonUrl: PropTypes.string,
        newPage: PropTypes.bool
    }),
    statList: PropTypes.arrayOf(PropTypes.shape({
        statNumber: PropTypes.string.isRequired,
        statLabel: PropTypes.string.isRequired
    }))
};

StatsBlock.defaultProps = {
    buttonBlock: {},
    statList: []
};
