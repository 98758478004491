import React from 'react';
import Image from '@components/atoms/Image';
import PropTypes from 'prop-types';

import './style.image-block.scss';

const ImageBlock = ({image, alignment, spacing}) => {

    const marginTopClass = spacing && spacing.marginTop ? 'hudl-block__margin-top' : '';
    const marginBottomClass = spacing && spacing.marginBottom ? 'hudl-block__margin-bottom' : '';
    const classes = [ 'hudl-offset-image-block', alignment, marginTopClass, marginBottomClass ];
    const hasImage = image && image.image;

    if (hasImage !== null){
        return (
            <div className={classes.join(' ')}>
                { hasImage && <Image imgsrc={image.image} title={image.imageTitle} alt={image.imageAlt} /> }
            </div>
        );
    }

    return null;

};

export default ImageBlock;

ImageBlock.propTypes = {
    image: PropTypes.shape({
        image: PropTypes.string.isRequired,
        imageAlt: PropTypes.string,
        imageTitle: PropTypes.string
    }).isRequired,
    alignment: PropTypes.string
};

ImageBlock.defaultProps = {
    alignment: 'image-left'
};
