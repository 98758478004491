import React from 'react';
import remark from 'remark';
import remarkHtml from 'remark-html';

const MarkdownField = ({
    className = '',
    content
}) => {

    const classes = [ 'hudl-markdown-field', className ];

    // Transform Netlify CMS markdown widget content into a html string.
    const htmlcontent = remark()
        .use(remarkHtml)
        .processSync(content);

    const splitString = htmlcontent.toString();

    return (
        <div
            className={classes.join(' ')}
            /* eslint-disable react/no-danger */
            dangerouslySetInnerHTML={{__html: splitString}}
        />
    );
};

export default MarkdownField;
