import React, {useContext, useEffect, useState, useRef} from 'react';
import initMarketoForms from '@components/atoms/MarketoForm/initMarketoForms';
import CmsContext from '@containers/CMS/CmsContext';
import {formAbandonment} from '@helpers/formAbandonment';
import PropTypes from 'prop-types';


/**
 * Marketo is used to power the forms across the Hudl website.
 * All the fields are controlled within the Marketo CMS, and to add
 * the form to a website, a simple script and form tag is used.
 *
 * The script is added from the FlexibleFields component, to ensure
 * that it is only called once for each formId per page.
 */

const MarketoForm = ({form, formSubmit, onFormSubmit, showThanksMessage}) => {

    if (typeof form === 'undefined') {
        return null;
    }

    const [ marketoReady, setMarketoReady ] = useState(false);

    const formReference = useRef(null);

    useEffect(() => {
        const {useEffectCallback} = initMarketoForms({form, marketoReady, setMarketoReady, formReference, formSubmit, onFormSubmit, showThanksMessage});
        useEffectCallback();
    }, [ formReference ]);

    const isCms = useContext(CmsContext);
    if (isCms) {
        return (
            <>
                <p>
                    Form previews not currently supported within the CMS - FormID:
                    <strong>
                        {form.formId}
                    </strong>
                </p>
            </>
        );
    }

    const beforeUnload = (e) => {
        e.preventDefault();
        formAbandonment({prevLocation: window.location});
    };

    useEffect(() => {
        window.addEventListener('beforeunload', beforeUnload, false);
    }, []);

    return (
        <>
            <form data-formid={form.formId} ref={formReference} />
        </>
    );
};

export default MarketoForm;

MarketoForm.propTypes = {
    formId: PropTypes.number.isRequired
};
