import React from 'react';
import Button from '@components/atoms/Button';
import ColonFix from '@components/atoms/ColonFix';
import MarkdownField from '@components/atoms/MarkdownField';

import './style.content-block-text.scss';

export default function ContentBlockText({pretitle, title, titleSize='gamma', content, buttonBlock, trackingComponent}) {
    const hasButton = buttonBlock && buttonBlock.buttonUrl && buttonBlock.buttonContent;
    return (
        <>
            { pretitle && <p className="hudl-delta-type hudl-pretitle">{pretitle}</p> }
            { title && (
                <h2 key={title.toLowerCase().replace(/\s/g, '')} className={`hudl-${titleSize}-type hudl-margin-bottom--xs`}>
                    <ColonFix string={title} />
                </h2>
            )}
            { content && (
                <div className="hudl-content-block-text__body">
                    <MarkdownField content={content} />
                </div>
            )}
            { hasButton && (
                <Button
                    component={trackingComponent}
                    variant={buttonBlock.buttonStyle}
                    to={buttonBlock.buttonUrl}
                    className="hudl-button"
                    newPage={buttonBlock.newPage}
                >
                    {buttonBlock.buttonContent}
                </Button>
            ) }
        </>
    );
}
