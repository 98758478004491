import React from 'react';
import Column from '@components/atoms/Column';
import Container from '@components/atoms/Container';
import LayoutContainer from '@components/atoms/LayoutContainer';
import MarkdownField from '@components/atoms/MarkdownField';
import PropTypes from 'prop-types';

import './style.icon-content-list-block.scss';

const IconContentListBlock = ({
    title,
    content,
    iconContentList,
    spacing
}) => {

    const marginTopClass = spacing && spacing.marginTop ? 'hudl-block__margin-top' : '';
    const marginBottomClass = spacing && spacing.marginBottom ? 'hudl-block__margin-bottom' : '';
    const classes = [ 'hudl-icon-content-list-block', 'hudl-padding-y--lg', marginTopClass, marginBottomClass ];
    const itemClasses = [ 'hudl-icon-content-list-block__list-item', 'hudl-padding--lg', 'hudl-padding--md--desktop' ];

    // Create list of Icon Content items.
    const iconContentItems = iconContentList.map((item, index) => {
        return (
            <li className={itemClasses.join(' ')} key={index.toString()}>
                <div className="hudl-icon-content-list-block__list-item-col">
                    { item.icon && <img src={item.icon} alt="" /> }
                </div>
                <div className="hudl-icon-content-list-block__list-item-col">
                    <strong>{item.title}</strong>
                    <p className="hudl-margin-bottom--none">{item.paragraph}</p>
                </div>
            </li>
        );
    });

    return (
        <Container>
            <div className={classes.join(' ')}>
                <LayoutContainer columns="two" alignment="center">
                    <Column className="hudl-column--one-thirds hudl-padding-x--sm--desktop hudl-margin-bottom--sm hudl-margin-bottom--none--desktop">
                        <h2 className="hudl-icon-content-list-block__title hudl-beta-type hudl-margin-bottom--xs">{title}</h2>
                        <MarkdownField content={content} />
                    </Column>
                    <Column className="hudl-column--two-thirds">
                        <ul className="hudl-icon-content-list-block__list">
                            {iconContentItems}
                        </ul>
                    </Column>
                </LayoutContainer>
            </div>
        </Container>
    );
};

export default IconContentListBlock;

IconContentListBlock.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired, // Markdown.
    iconContentList: PropTypes.arrayOf(PropTypes.shape({
        icon: PropTypes.string.isRequired, // Image path.
        title: PropTypes.string.isRequired,
        paragraph: PropTypes.string.isRequired
    }))
};

IconContentListBlock.defaultProps = {
    iconContentList: []
};
