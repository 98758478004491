import React, {useContext} from 'react';
import CmsContext from '@containers/CMS/CmsContext';
import {useStaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image';

const Image = ({
    aspectRatio = null,
    imgsrc,
    title,
    alt
}) => {

    const isCms = useContext(CmsContext);
    if (isCms) {
    // No static queries allowed in CMS preview, so return simple image.
        return <img src={imgsrc} alt={alt} title={title} />;
    }

    // Get extension.
    const fileExtension = imgsrc.split('.').pop();

    let img = null;
    if (fileExtension === 'svg') {
    // SVG.
        img = <img src={imgsrc} alt={alt} title={title} />;
    }
    else {
    // JPG/PNG/etc.
        const imgFilename = imgsrc.split('/').pop();
        const data = useStaticQuery(graphql`
            query {
                allImageSharp {
                    edges {
                        node {
                            fluid {
                                ...GatsbyImageSharpFluid
                                originalName
                            }
                        }
                    }
                }
            }
        `);
        const imgNode = data.allImageSharp.edges.find((item) => {
            return item.node.fluid.originalName === imgFilename;
        });

        // Added catch for when images have been add in one place and removed somewhere else.
        // These errors will need to be reviewed to inform the content team about errors in the CMS.
        if (typeof imgNode === 'undefined') {
            // eslint-disable-next-line no-console
            console.error('Failed to find the image: ', imgFilename);
            return null;
        }

        let {fluid} = imgNode.node;
        if (aspectRatio) {
            fluid = {...fluid, aspectRatio};
        }
        img = <Img fluid={fluid} alt={alt} title={title} />;
    }

    return img;
};

export default Image;
