import React from 'react';

/**
 * @param {object} notches The notches config object.
 * @param {array} classArray Array of classes that will be added to the component parent, but must be amended here.
 * @returns {function} Returns the function that outputs the notch elements.
 */
const notchHelper = ({notches, classArray}) => {

    // ToDo: Lines 14 and 15 repeat line 12. Needs tidying up.

    if (notches && notches !== null && typeof notches !== 'undefined') {

        const hasBottomNotch = notches !== null && notches.notchVisible === 'true' && notches.notchesList !== null ? notches.notchesList.some((e) => e.notchConfig.notchPosition.includes('bottom')) : false;
        const hasTopNotch = notches !== null && notches.notchVisible === 'true' && notches.notchesList !== null ? notches.notchesList.some((e) => e.notchConfig.notchPosition.includes('top')) : false;

        if (hasBottomNotch) {
            classArray.push('hudl-notch-bottom-padding');
        }

        if (hasTopNotch) {
            classArray.push('hudl-notch-top-padding');
        }

        const NotchLayout = ({notchesOptions}) => {
            if (notches !== null && notchesOptions.notchVisible === 'true' && notchesOptions && notchesOptions.notchesList !== null ) {
                return notchesOptions.notchesList.map((notch, index) => {
                    const notchNumber = index;
                    return <div key={`notch-${notchNumber}`} className={`hudl-notch hudl-notch--${notch.notchConfig.notchColour} hudl-notch--${notch.notchConfig.notchPosition} hudl-notch--${notch.notchConfig.notchSize}`} />;
                });
            }
            return null;
        };

        return {
            NotchLayout
        };
    }

    return new Error('Notches equals Null');

};

export default notchHelper;
