import React from 'react';
import Button from '@components/atoms/Button';
import Container from '@components/atoms/Container';
import MarkdownField from '@components/atoms/MarkdownField';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';

import './style.video-block.scss';

const VideoBlock = ({
    video,
    buttonBlockOptional: buttonBlock,
    textAlign='left',
    spacing
}) => {

    const marginTopClass = spacing && spacing.marginTop ? 'hudl-block__margin-top' : '';
    const marginBottomClass = spacing && spacing.marginBottom ? 'hudl-block__margin-bottom' : '';
    const classes = [ 'hudl-video-block', 'hudl-padding-y--lg', textAlign ? `hudl-text-align--${textAlign}` : '', marginTopClass, marginBottomClass ];
    const hasButton = buttonBlock && buttonBlock.buttonUrl && buttonBlock.buttonContent;

    return (
        <div className={classes.join(' ')}>
            <Container size="medium">
                <div className="hudl-video-block__pre-content hudl-margin-bottom--sm">
                    <h2 className="hudl-gamma-type">{video.videoTitle}</h2>
                    <MarkdownField content={video.contentOptional} />
                </div>
                <div className="hudl-video-block__video-wrapper">
                    <ReactPlayer controls="true" className="hudl-video-block__video" height="100%" width="100%" url={video.videoUrl} />
                </div>
                { hasButton && (
                    <div className="hudl-video-block__button-wrapper hudl-text-align--center hudl-margin-top--xl">
                        <Button variant={buttonBlock.buttonStyle} to={buttonBlock.buttonUrl} className="hudl-button" newPage={buttonBlock.newPage}>{buttonBlock.buttonContent}</Button>
                    </div>
                )}
            </Container>
        </div>
    );
};

export default VideoBlock;

VideoBlock.propTypes = {
    video: PropTypes.shape({
        videoTitle: PropTypes.string,
        contentOptional: PropTypes.string, // Markdown.
        videoUrl: PropTypes.string.isRequired
    })
};

VideoBlock.defaultProps = {
    video: {
        videoTitle: '',
        contentOptional: ''
    }
};
