/**
 * Added switch for correcting language folders for language codes when they are different.
 */
export default ({language}) => {
    switch (language) {
    case 'es':
        return 'es-xl';
    default:
        return language;
    }
};
