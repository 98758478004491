import React from 'react';
import ImageBlock from '@components/molecules/imageBlock';
import OneColumnText from '@components/molecules/OneColumnText';
import OneTrustCookieBlock from '@components/molecules/OneTrustCookieBlock';
import VideoBlock from '@components/molecules/videoBlock';
import ContentBlock from '@components/organisms/ContentBlock';
import FormImageBlock from '@components/organisms/FormImageBlock';
import HeroBlock from '@components/organisms/HeroBlock';
import IconContentListBlock from '@components/organisms/IconContentListBlock';
import InteractiveImageBlock from '@components/organisms/InteractiveImageBlock';
import LinkedContentBlocks from '@components/organisms/LinkedContentBlocks';
import LogoStrip from '@components/organisms/LogoStrip';
import QuoteBlock from '@components/organisms/QuoteBlock';
import RelatedArticlesBlock from '@components/organisms/RelatedArticlesBlock';
import ScrollerBlocks from '@components/organisms/ScrollerBlocks';
import StatsBlock from '@components/organisms/StatsBlock';

import './style.flexible-fields.scss';

/**
 * A wrapper component for the flexible field components. This wraps the
 * component in a div with a class, and section ID.
 * @param {dom elements} children Components that are wrapped by this component.
 * @param {string} sectionId Unique ID for this component.
 * @return {dom element}
 */
const FieldWrapper = ({children, sectionId}) => {
    return (
        <div id={sectionId} className="flexible-field">
            {children}
        </div>
    );
};

/**
 * A compiler component to take an array of fields, and order the correct
 * flexible field components.
 * @param {array} fields Flexible fields array.
 * @return {dom element}
 */
const FlexibleFields = props => {
    const {fields, formSubmit, onFormSubmit} = props;

    if (!fields) {
        return null;
    }

    // Loop through the fields and return a component for each field.
    return fields.map((field, index) => {

        let block = null;

        // Check if the type in this array node is what needs to be made.
        // If it is, pass the correct values to the component.
        switch (field.type) {
        case 'heroBlock':
            block = <HeroBlock {...field} index={index} />;
            break;
        case 'contentBlock':
            block = <ContentBlock index={index} fieldCount={fields.length} {...field} />;
            break;
        case 'logoStrip':
            block = <LogoStrip {...field} />;
            break;
        case 'quoteBlock':
            block = <QuoteBlock {...field} />;
            break;
        case 'formImageBlock':
            block = <FormImageBlock formSubmit={formSubmit} onFormSubmit={onFormSubmit} {...field} />;
            break;
        case 'statsBlock':
            block = <StatsBlock {...field} />;
            break;
        case 'oneColumnText':
            block = <OneColumnText {...field} />;
            break;
        case 'oneTrustCookieBlock':
            block = <OneTrustCookieBlock {...field} />;
            break;
        case 'imageBlock':
            block = <ImageBlock {...field} />;
            break;
        case 'videoBlock':
            block = <VideoBlock {...field} />;
            break;
        case 'iconContentListBlock':
            block = <IconContentListBlock {...field} />;
            break;
        case 'interactiveImageBlock':
            block = <InteractiveImageBlock {...field} />;
            break;
        case 'relatedArticlesBlock':
            block = <RelatedArticlesBlock {...field} />;
            break;
        case 'linkedContentBlocks':
            block = <LinkedContentBlocks {...field} />;
            break;
        case 'scrollerBlocks':
            block = <ScrollerBlocks {...field} />;
            break;
            // An associated type was not found, so return null.
        default:
            throw new Error(`Flexible field type: ${field.type} not found.`);
        }

        if (block) {
            const k = `${field.title}-${index}`;
            return (
                <FieldWrapper
                    sectionId={field.sectionId}
                    key={k}
                >
                    {block}
                </FieldWrapper>
            );
        }

        return null;
    });
};

export default FlexibleFields;
