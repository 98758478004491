import React, {useContext} from 'react';
import CmsContext from '@containers/CMS/CmsContext';
import {useStaticQuery, graphql} from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

const BgImage = ({imgsrc, tag, bgColor, children, className}) => {

    if (!imgsrc) {
        return (
            <div>{children}</div>
        );
    }

    const isCms = useContext(CmsContext);
    if (isCms) {
    // No static queries allowed in CMS preview, so return children inside div.
        const bgUrl = `url(${imgsrc})`;
        return (
            <div style={{
                backgroundImage: bgUrl,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
            }}
            >
                {children}
            </div>
        );
    }

    const imgFilename = imgsrc.split('/').pop();
    const data = useStaticQuery(graphql`
        query {
            allImageSharp {
                edges {
                    node {
                        fluid {
                            ...GatsbyImageSharpFluid
                            originalName
                        }
                    }
                }
            }
        }
    `);
    const imgNode = data.allImageSharp.edges.find((item) => {
        return item.node.fluid.originalName === imgFilename;
    });

    return (
        <BackgroundImage className={className} Tag={tag} fluid={imgNode.node.fluid} backgroundColor={bgColor}>
            {children}
        </BackgroundImage>
    );
};

export default BgImage;
