import getPrevPage from '@helpers/getPrevPage';
import {podId, munchkinId} from '@netlify/site-settings/marketo.yml';
import {navigate} from 'gatsby';

const isBrowser = typeof window !== 'undefined';

if (isBrowser) {
    window.HUDL = window.HUDL || {};
    window.HUDL.forms = window.HUDL.forms || [];
}

const formListener = ({formEl}) => {
    return (e) => {
        window.HUDL.forms = window.HUDL.forms.map(formItem => {
            if (formItem.id === formEl.id.replace('mktoForm_', '')) {
                formItem.history.push(e.target.name);
            }
            return formItem;
        });
    };
};

const addFormListener = ({formID, formListenerFn}) => {
    return () =>
        Array.from(document.querySelectorAll(`#${formID} input, #${formID} textarea, #${formID} select`))
            .forEach(item => item.addEventListener('change', formListenerFn));
};

const removeFormListener = ({formID, formListenerFn}) => {
    return () =>
        Array.from(document.querySelectorAll(`#${formID} input, #${formID} textarea, #${formID} select`))
            .forEach(item =>
                item.removeEventListener('change', formListenerFn));
};

const initMarketoForms = ({form, marketoReady, setMarketoReady, formReference, formSubmit, onFormSubmit, showThanksMessage=false}) => {

    const pushFormDataToDataLayer = (formEvent, formValues, getPrevPageValue) => {

        window.dataLayer.push({
            event: formEvent,
            content: formValues,
            url: window.document.URL,
            language: window.document.documentElement.lang,
            previous_page: getPrevPageValue
        });
    };

    const useEffectCallback = () => {
        let formObj = null;
        // We need to wait for the form to load onto the page before we can do any dom manipulations.
        // We need to perform dom manipulations to remove the inline style attributes so that we can apply our styles.
        if (typeof MktoForms2 !== 'undefined' && isBrowser === true) {
            MktoForms2.whenReady((fm) => {
                const formEl = fm.getFormElem()[0];
                formEl.removeAttribute('style');

                Object.entries(formEl).forEach(element => {
                    if (element[1].style) {
                        element[1].removeAttribute('style');
                    }
                });

                window.HUDL.forms
                    .filter(theForm => theForm.id === formEl.id.replace('mktoForm_', ''))
                    .forEach(theForm => theForm.addFormListener());

                fm.onSuccess ( (formValues) => {
                    pushFormDataToDataLayer ('form_submit', formValues, getPrevPage(window.dataLayer) );

                    if(showThanksMessage && showThanksMessage === true) {
                        onFormSubmit(true);
                    } else {

                        // ToDo: Get default country environment variables.
                        const pathName = window.location.pathname;
                        const languagePath = pathName.split('/')[1];

                        navigate(`/${languagePath}/thank-you`);

                    }
                    return false;
                });

                formObj = fm;
            });
        }

        return formObj;
    };

    /* globals MktoForms2 */
    if (typeof MktoForms2 !== 'undefined' && marketoReady === false) {
        const loadForm = MktoForms2.loadForm.bind(MktoForms2, podId, munchkinId, form.formId);
        const formEl = formReference.current;

        (function loadFormCb(formEls) { // eslint-disable-line no-shadow
            formEl.id = `mktoForm_${form.formId}`;
            const formListenerFn = formListener({formEl});
            // Set up array for this forms to store field history.
            window.HUDL.forms.push({
                id: String(form.formId),
                form: loadForm(),
                history: [],
                addFormListener: addFormListener({formID: `mktoForm_${form.formId}`, formListenerFn}),
                removeFormListener: removeFormListener({formID: `mktoForm_${form.formId}`, formListenerFn}),
                formListener: formListenerFn
            });

        })(formEl);
        setMarketoReady(true);
    }

    return {useEffectCallback};

};

export default initMarketoForms;
